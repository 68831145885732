.invoice-box {
  max-width: 800px;
  min-width: 760px;
  margin: auto;
  border: 1px solid #eee;
  background-color: white;
  width: 100%;
  padding: 5%;
  font-size: 12px;
  line-height: 14px;
  font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
  color: #555;
  min-height: 1000px;
  position: relative;
}

div.divFooter {
  /* position: absolute;
    width: 100%;
    bottom: 0; */
  margin-bottom: 10px;
  margin-top: 30px;
  position: absolute;
  bottom: 30px;
  width: 100%;
}

.terms-block {
  position: absolute;
  bottom: 140px;
  width: 82%;
}

.dummy-invoice-box {
  margin: 10px auto 20px auto;
  width: 45%;
  text-align: left;
  padding: 30px;
  box-shadow: 0px 0px 6px 4px #00000026;
  max-width: 800px;
  min-width: 300px;
  border: 1px solid #eee;
  background-color: white;
  font-size: 12px;
  line-height: 14px;
  font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
  color: #555;
  min-height: 850px;
  position: relative;
}

.dummy-logo {
  width: 100px;
  border: 1px solid;
  border-radius: 100%;
  font-weight: bold;
  font-size: 1rem;
  padding: 25px;
  height: 100px;
  text-align: center;
  display: inline-flex;
  align-self: center;
  justify-content: center;
  align-items: center;
}


@media print {
  .terms-block {
    position: absolute;
    bottom: 60px;
    width: 82%;
  }

  div.divFooter {
    position: fixed;
    width: 100%;
    margin-top: 10px;
    bottom: 10px;
    display: block;
  }

  .invoice-box {
    max-width: unset;
    margin: auto;
    border: unset;
    box-shadow: unset;
    width: 100%;
    padding: 5%;
    font-size: 26px;
    font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
    color: #555;
  }
}


@media screen and (max-width: 1024px) {
  .dummy-invoice-box {
    width: 100%;
    min-height: 1000px;
  }
}